.user {
  color: #1d5a08;
}
.user a {
  color: #6e8f43;
  text-decoration: none;
}
.user a:hover {
  color: #e735bd;
}
.user-title {
  color: salmon;
}
.user-create-title {
  color: #cb1919;
}

.user-data {
  font-weight: bold;
  color: #479aa9;
}
.user-data-updated {
  color: #e58f86;
}

.sure {
  color: red;
  font-weight: bold;
}

/*# sourceMappingURL=Users.css.map */
