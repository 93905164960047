.shake-bottom,
button:hover {
  animation: shake-bottom 0.8s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}

/* ----------------------------------------------
 * * Generated by Animista on 2018-9-9 3:0:14
 * * w: http://animista.net, t: @cssanimista
 * * ---------------------------------------------- */
/**
 * * ----------------------------------------
 * * animation shake-bottom
 * * ----------------------------------------
 * */
@keyframes shake-bottom {
  0%, 100% {
    transform: rotate(0deg);
    transform-origin: 50% 100%;
  }
  10% {
    transform: rotate(2deg);
  }
  20%, 40%, 60% {
    transform: rotate(-4deg);
  }
  30%, 50%, 70% {
    transform: rotate(4deg);
  }
  80% {
    transform: rotate(-2deg);
  }
  90% {
    transform: rotate(2deg);
  }
}
.roll-in-left {
  -webkit-animation: roll-in-left 0.6s ease-out both;
  animation: roll-in-left 0.6s ease-out both;
}

/* ----------------------------------------------
 * * Generated by Animista on 2018-9-15 1:14:10
 * * w: http://animista.net, t: @cssanimista
 * * ---------------------------------------------- */
/**
 * * ----------------------------------------
 * * animation roll-in-left
 * * ----------------------------------------
 * */
@keyframes roll-in-left {
  0% {
    -webkit-transform: translateX(-800px) rotate(-540deg);
    transform: translateX(-800px) rotate(-540deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) rotate(0deg);
    transform: translateX(0) rotate(0deg);
    opacity: 1;
  }
}
.bounce-in-left {
  -webkit-animation: bounce-in-left 1.1s both;
  animation: bounce-in-left 1.1s both;
}

/* ----------------------------------------------
 * * Generated by Animista on 2018-9-15 1:24:26
 * * w: http://animista.net, t: @cssanimista
 * * ---------------------------------------------- */
/**
 * * ----------------------------------------
 * * animation bounce-in-left
 * * ----------------------------------------
 * */
@keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-600px);
    transform: translateX(-600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(-68px);
    transform: translateX(-68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(-28px);
    transform: translateX(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, Courier New, monospace;
}

* {
  margin: 0;
  padding: 0;
}

html,
body {
  height: 100%;
}

body {
  overflow-y: scroll;
  background: #d7e0e1;
  min-width: 1140px;
}

.wrapper, #root {
  display: flex;
  flex-direction: column;
  position: relative;
}
.wrapper .content, #root .content {
  flex: 1 0 auto;
  width: 1140px;
  margin: 0 auto 99px auto;
  background: #eaeeea;
}
.wrapper .content .main-content, #root .content .main-content {
  margin: 55px 2% 0 2%;
}
.wrapper .content .main-content .table, #root .content .main-content .table {
  border: 1px solid #69c;
  border-collapse: separate;
  empty-cells: hide;
}
.wrapper .content .main-content .table th, .wrapper .content .main-content td, #root .content .main-content .table th, #root .content .main-content td {
  border: 2px solid #69c;
  padding: 5px;
}
.wrapper .content .main-content .table a, #root .content .main-content .table a {
  text-decoration: none;
  color: chocolate;
}
.wrapper .content .main-content .table a:hover, #root .content .main-content .table a:hover {
  color: red;
}

/*# sourceMappingURL=global.css.map */
