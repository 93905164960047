.project-data-set a {
  text-decoration: none;
  color: #003366;
  font-weight: bold;
}
.project-data-set a:hover {
  color: #009926;
}
.project-data-set .project-data {
  font-weight: bold;
  color: #761c19;
}
.project-data-set .project-data-updated {
  color: #e58f86;
}

/*# sourceMappingURL=Projects.css.map */
