.todos-data .comment {
  font-size: 1.2em;
  color: #1d5a08;
}
.todos-data .comment-info {
  font-size: 0.9em;
}
.todos-data .comment-info .comment-user {
  font-size: 1.3em;
  padding-right: 0.5em;
  text-decoration: none;
  color: #003366;
  font-weight: bold;
}
.todos-data .comment-info .comment-user:hover {
  color: #009926;
}
.todos-data .comment-info .comment-datetime {
  color: #5a626c;
  font-weight: bold;
  padding-right: 0.5em;
}
.todos-data .comment-info .comment-updated {
  color: #e58f86;
}

/*# sourceMappingURL=Todos.css.map */
