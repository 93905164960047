.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.header {
  width: 1140px;
  position: fixed;
  z-index: 1000;
  background: #ddffdd;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  opacity: 0.9;
  max-height: 44px;
}
.header .menu ul {
  list-style-type: none;
  padding: 0;
}
.header .menu ul li {
  display: inline-block;
  margin: inherit;
}
.header .menu ul li .menu-link {
  text-decoration: none;
  color: salmon;
  border: 2px salmon solid;
  padding: 5px;
  margin: 5px;
  cursor: pointer;
}
.header .menu ul li .menu-link:hover {
  color: crimson;
  background: gainsboro;
}
.header .menu ul li .menu-login {
  border: 2px salmon dashed;
}
.header .menu ul li .menu-logout {
  border: 2px #f5351f dashed;
}

/*# sourceMappingURL=Header.css.map */
