.asyncio-title {
  color: #cc25d3;
}
.asyncio-id {
  color: rebeccapurple;
  font-weight: bold;
}
.asyncio-text {
  color: #105b8a;
}

/*# sourceMappingURL=AsyncioAiohttp.css.map */
