.footer {
  text-align: center;
  background: #061f26;
  width: 1140px;
  margin: 0 auto;
  position: fixed;
  bottom: 0;
  justify-content: center;
  padding-bottom: 10px;
}

.App-logo {
  position: absolute;
  height: 400px;
  pointer-events: none;
  width: 550px;
  display: inline-block;
  justify-content: center;
  text-align: center;
  z-index: 1000;
  opacity: 0.6;
  bottom: 50px;
  margin: 0 auto;
  left: 303px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.App-link {
  color: #61dafb;
  z-index: 1001;
  text-decoration: none;
}

/*# sourceMappingURL=Footer.css.map */
